<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>

            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Name -->
                  <b-col :md="media.id > 0 ? 12 : 6">
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="media.name"
                          maxlength="45"
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Company -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="media.companyId"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="allCompanies"
                          :reduce="type => type.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Language -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >

                        <v-select
                          id="id-language"
                          v-model="media.language"
                          :options="languageOptions"
                          label="name"
                          :clearable="false"
                          :reduce="type => type.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Selected file -->
                  <b-col
                    v-if="media.id===0"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('SELECT_FILE')+ ' (*.mp4,*.mov)'"
                      label-for="id-url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SELECT_FILE')+ ' (*.mp4,*.mov)'"
                        rules="required"
                      >
                        <b-form-file
                          v-model="media.selectedFile"
                          :placeholder="$t('CHOOSE_FILE_OR_DROP')"
                          drop-placeholder="Drop file here..."
                          accept="video/*"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="media.description"
                        maxlength="300"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- Submit and Reset -->
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="enabledSpinner"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ media.mediaID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="enabledSpinner"
                    md="3"
                  >
                    <div>
                      <b-spinner
                        variant="primary"
                        style="width: 2rem; height: 2rem;"
                        label="Small Spinner"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BFormFile,
  BButton, BForm, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions, mapState } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BFormFile,
    BSpinner,
  },
  data() {
    return {
      media: {
        id: 0,
        companyId: '',
        url: '',
        name: '',
        description: '',
        language: '',
        selectedFile: null,
      },
      language: '',
      languageOptions: constants.LANGUAGES,
      required,
      enabledSpinner: false,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your media description',
    }

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      editorOption,
      successMessage,
      showErrorMessage,
    }
  },
  async created() {
    const mediaId = parseInt(router.currentRoute.params.id, 10)
    if (mediaId) {
      this.media.id = mediaId
      const response = await this.fetchMediaByID(mediaId)
      if (response?.data) {
        this.media = response.data
      }
      this.fetchCompany()
    } else {
      this.fetchCompany()
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('company', ['allCompanies']),
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('media', ['createMedia', 'updateMedia', 'fetchMediaByID']),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.enabledSpinner = true
          if (this.media.id) {
            this.updateMedia(this.media).then(response => {
              if (response) {
                this.enabledSpinner = false
                this.successMessage(this.$i18n.t('MESSAGE.MEDIA_UPDATED'))
                this.$router.push({ name: 'media-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            this.createMedia(this.media).then(response => {
              if (response) {
                this.enabledSpinner = false
                this.successMessage(this.$i18n.t('MESSAGE.MEDIA_CREATED'))
                this.$router.push({ name: 'media-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
    fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.companies = this.getAllCompanie
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/quill.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
}
</style>
